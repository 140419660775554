import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Layout from "@components/Layout";
import PageHeaderWrapper from "@components/PageHeaderWrapper";
import { Heading } from "@components/Typography";
import tickV from "@assets/img/tick-v.png";
import { ScrollAnimation } from "@components/ScrollAnimation";
import { Circle } from "@components/decorator";
import { DotMap } from "@components/decorator";

import AboutUseHeaderImage from "@assets/img/header/about-us.png";
import ImageOne from "@assets/img/about-us-1.png";
import ImageOneLayer from "@assets/img/about-us-1-layer.png";
import ImageTwo from "@assets/img/about-us/about-us-2.png";
import ImageThree from "@assets/img/about-us/about-us-3.png";
import ImageTwoLayer from "@assets/img/about-us/about-us-2-layer.png";
import ImageThreeLayer from "@assets/img/about-us/about-us-3-layer.png";
import ImageThreeDesktop from "@assets/img/about-us/about-us-3-desktop.png";
import ImageThreeLayerDesktop from "@assets/img/about-us/about-us-3-layer-desktop.png";
import ImageOutTeamDesktop from "@assets/img/about-us/out-team-desktop.png";
import ImageOutTeamLayerDesktop from "@assets/img/about-us/out-team-layer-desktop.png";
import ImageOutTeam from "@assets/img/about-us/out-team-mobile.png";
import ImageOutTeamLayer from "@assets/img/about-us/out-team-layer-mobile.png";

import { useTranslation } from 'react-i18next';

const AboutUsPage = () => {
  const data = useStaticQuery(query)
  const { t } = useTranslation()
  const pageTrans = 'aboutUs:'

  useEffect(() => {
    document.addEventListener('sal:in', scrollHandler)
    return () => document.removeEventListener('sal:in', scrollHandler)
  }, [])

  const scrollHandler = ({ detail }) => {
    if (detail.target.classList.value.includes('animate-image')) {
      setTimeout(() => {
        detail.target.querySelectorAll('.animate-image__item').forEach((elm) => {
          elm.classList.remove('hidden')
          elm.classList.add('animate__flipInY', 'animate__slow')
        })
      }, 100)
    }
  }

  return (
    <Layout seo={data.strapiHomepage.seo} navbarTheme="light">
      <PageHeaderWrapper
        className="bg-center bg-no-repeat bg-cover relative"
        style={{ backgroundImage: `url(${AboutUseHeaderImage})` }}
      >
        <div className="bg-blue-900 bg-opacity-80 absolute top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10 text-white flex justify-center items-center flex-col h-[343px] lg:h-[414px] lg:pb-[30px]">
          <Heading h={3} className="text-5xl animate__animated animate__fadeInUp pt-10 lg:hidden block" style={{ marginBottom: 10 }}>{t('menu.aboutUs')}</Heading>
          <Heading h={2} className="animate__animated animate__fadeInUp pt-8 mb-[-34px] lg:block hidden" style={{ marginBottom: 10 }}>{t('menu.aboutUs')}</Heading>
          <p className="animate__animated animate__fadeInUp animate__delay-2s tracking-wider">{t('menu.home')}&nbsp;<span className="inline-block scale-[1.5] font-thin">/</span>&nbsp;{t('menu.aboutUs')}</p>
        </div>
      </PageHeaderWrapper>

      <section className="lg:pt-[150px]">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="lg:col-span-7 lg:order-last">
              <div className="lg:mt-10">
                <div className="relative pb-1 pt-6 lg:pt-1 lg:mb-[-10px]">
                  <DotMap className="top-[-56px] right-[-47px] lg:block hidden" style={{ height: 110, width: 110, zIndex: 1 }} color="#2263CB"/>  
                  <div className="absolute z-[-1] top-[-28px] right-[-186px] lg:block hidden">
                    <Circle className="animate__delay-2s h-[205px] w-[205px] bg-orange-100"/>
                  </div>
                  <Heading
                    h={2}
                    className="text-5xl text-blue-900 text-opacity-5  mb-[16px] animate__animated animate__fadeInTopLeft lg:hidden block"
                  >
                    Our Mission
                  </Heading>
                  <Heading
                    h={2}
                    className="lg:mt-[-26px] lg:pl-[24px] text-blue-900 text-opacity-5  mb-[16px] animate__animated animate__fadeInTopLeft lg:block hidden "
                    style={{ fontSize: 70 }}
                  >
                    Our Mission
                  </Heading>
                  <Heading
                    h={3}
                    className="lg:text-4xl lg:pl-[21px] lg:pr-16 lg:bt-[29px] lg:bottom-[7px] text-3xl absolute left-[20px] bottom-0  text-blue animate__animated animate__fadeInUp animate__delay-3s"
                    style={{ marginBottom: 21 }}
                  >
                    {t(`${pageTrans}section1.heading`)}
                  </Heading>
                </div>

                <ScrollAnimation className=" lg:pl-[46px]">
                  <div className="flex items-center mt-3">
                    <img className="w-[21px] h-[14.36px] ml-[2px]" src={tickV} alt="icon" />
                    <p className="pl-4 text-navy-400 text-lg">
                      {t(`${pageTrans}section1.content1`)}
                    </p>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation className=" lg:pl-[46px]">
                  <div className="flex items-center mt-6">
                    <img className="w-[21px] h-[14.36px] ml-[2px]" src={tickV} alt="icon" />
                    <p className="pl-4 text-navy-400 text-lg">
                      {t(`${pageTrans}section1.content2`)}
                    </p>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation className=" lg:pl-[46px]">
                  <div className="flex items-center mt-6">
                    <img className="w-[21px] h-[14.36px] ml-[2px]" src={tickV} alt="icon" />
                    <p className="pl-4 text-navy-400 text-lg">
                      {t(`${pageTrans}section1.content3`)}
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="lg:col-span-5 lg:order-first">
              <div className="mb-6 h-[455.53px] relative ml-[-55px] mt-2">
                <div className="absolute z-[-1] h-[186px] w-[186px] top-[-64px] right-[-78px] md:hidden">
                  <Circle className="animate__delay-2s h-[186px] w-[186px] bg-orange-100"/>
                </div>
                <DotMap className="top-[-69px] left-[-8px] lg:block hidden" style={{ height: 110, width: 110, zIndex: 1 }} color="#2263CB"/>  
                <div className="mt-5 mb-7 w-[461.63px] h-[455.53px] col-span-5 absolute top-[-11px] left-[-3px] z-10 xl:left-[72px] md:left-1/2 md:-translate-x-1/2 xl:-translate-x-0">
                  <ScrollAnimation animation="zoom-in" delay={800} duration={800} className="mt-3 animate-image">
                    <div className="absolute top-0 left-0 lg:top-[8px]  z-10 w-[461.63x] h-[455.53px]">
                      <img src={ImageOne} className="w-full h-full"/>
                    </div>
                  </ScrollAnimation>

                </div>
                <div className="absolute z-[-1] top-[15px] lg:top-[29px] left-[-4px] w-[461px] h-[469px] xl:left-[70px] md:left-1/2 md:-translate-x-1/2 xl:-translate-x-0">
                  <div
                    className="bg-no-repeat bg-center h-[469px]"
                    style={{
                      backgroundImage: `url(${ImageOneLayer})`,
                      backgroundSize: "461px 469px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <section>
          <div className="container">
            <div className="lg:grid lg:grid-cols-12 lg:pt-24 xl:pt-40">
              <div className="xl:mt-24 xl:pt-4 lg:mt-8 mt-16 pt-2 lg:col-span-7">
                <div className="pt-1 lg:pl-10">
                  <ScrollAnimation>
                    <h2 className="text-4xl font-bold text-blue-700">
                      {t(`${pageTrans}section2.heading`)}
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation>
                    <p className="mt-6 text-lg lg:mt-8 lg:pr-6">
                      {t(`${pageTrans}section2.content1`)}
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="lg:col-span-5 lg:pl-3 lg:mt-[-20px]">
                <div className="flex justify-center">
                  <div className="relative h-[364px] w-[342px] mt-6 xl:h-[478px] lg:mt-0">
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image">
                      <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[342px] h-[364px] xl:w-[454px] xl:h-[478px]">
                        <img src={ImageTwo} className="w-full h-full"/>
                      </div>
                    </ScrollAnimation>
                    <div className="absolute top-0 left-0 w-[343px] h-[341px] z-1 xl:h-[465px] xl:w-[463px] ">
                      <img src={ImageTwoLayer} className="w-full h-full"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="lg:pl-10 xl:mt-24 xl:pt-6 lg:mt-18 lg:pt-0  mt-20">
              <div className="lg:grid lg:grid-cols-12">
                <div className="lg:col-span-10">
                  <ScrollAnimation>
                    <h2 className="lg:text-4xl text-3xl font-extrabold text-blue-700">
                      {t(`${pageTrans}section3.heading`)}
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation>
                    <p className="lg:mt-6 mt-4 text-lg">
                      {t(`${pageTrans}section3.content1`)}
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="flex justify-center lg:justify-start xl:justify-center">
              <div className="relative h-[364px] w-[363px] lg:h-[493px] lg:w-full xl:mt-16 lg:mt-0 mt-6 lg:scale-75 xl:lg:scale-100">
                <DotMap className="top-[-165px] xl:right-[-47px] lg:right-[-180px] lg:block hidden" style={{ height: 192, width: 169, zIndex: 11 }} color="#FCCB77"/>  
                <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image lg:hidden block">
                  <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[363px] h-[325px] lg:hidden block">
                    <img src={ImageThree} className="w-full h-full ml-[-10px] mt-[-2px]"/>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image lg:block hidden">
                  <div className="animate-image__item animate__animated absolute top-0 left-0 lg:left-[-115px] xl:left-0 animate__delay-1s z-10 w-[1175px] h-[493px] lg:block hidden pl-3">
                    <img src={ImageThreeDesktop} className="w-full h-full ml-[-10px] mt-[-2px]"/>
                  </div>
                </ScrollAnimation>
                <div className="absolute top-0 left-0 w-[343px] h-[305px] z-1 lg:hidden block">
                  <img src={ImageThreeLayer} className="w-full h-full"/>
                </div>
                <div className="absolute top-0 left-0 lg:left-[-115px] xl:left-0 w-[1154px] h-[473px] z-1 lg:block hidden pl-3">
                  <img src={ImageThreeLayerDesktop} className="w-full h-full"/>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section>
          <div className="container mb-3">
            <div className="lg:grid lg:grid-cols-12 lg:items-center">
              <div className="lg:col-span-6">
                <div className="lg:pl-5 xl:mt-[-69px] lg:mt-12 lg:pr-4 mt-8">
                  <ScrollAnimation>
                    <h2 className="lg:text-4xl text-3xl font-extrabold text-blue-700">
                      {t(`${pageTrans}section4.heading`)}
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation>
                    <p className="lg:mt-5 mt-4 text-lg">
                      {t(`${pageTrans}section4.content1`)}
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="lg:col-span-6 xl:pt-4">
                <div className="flex justify-center">
                  <div className="relative h-[306px] w-[338px] xl:h-[589px] xl:mt-24 lg:mt-12 mt-8">
                    <div className="absolute bg-orange-200 rounded-tr-[30px] rounded-bl-[10px] w-[69px] h-[69px] xl:w-[121px] xl:h-[121px] top-[25px] left-[-16px] lg:top-[58px] lg:left-[-42px]"></div>
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-4 animate-image xl:hidden block">
                      <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[338px] h-[303px] ml-[10px]">
                        <img src={ImageOutTeam} className="w-full h-full ml-[-10px] mt-[-2px]"/>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animation="fade" delay={10} duration={10} className="mt-3 animate-image xl:block hidden">
                      <div className="animate-image__item animate__animated absolute top-0 left-0 animate__delay-1s z-10 w-[542px] h-[487px] pl-3 ml-[10px]">
                        <img src={ImageOutTeamDesktop} className="w-full h-full ml-[-10px] mt-[-2px]"/>
                      </div>
                    </ScrollAnimation>
                    <div className="absolute top-0 left-0 w-[343px] h-[287px] z-1 xl:hidden block">
                      <img src={ImageOutTeamLayer} className="w-full h-full"/>
                    </div>
                    <div className="absolute top-0 left-0 w-[555px] h-[467px] z-1 xl:block hidden pl-3">
                      <img src={ImageOutTeamLayerDesktop} className="w-full h-full"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export default AboutUsPage;
